import marketingImage1 from "../../images/blogPosts/image1.png";
import marketingImage2 from "../../images/blogPosts/image2.png";
import marketingImage3 from "../../images/blogPosts/image3.png";

export const MarketingData = [
  {
    image: marketingImage1,
    publishBy: "Alosia",
    publishTitle: "WhatsApp Marketing for Businesses in 2024!",
    publishDescription:
      "Businesses are constantly searching for new avenues to connect with customers, boost sales, and skyrocket their ROI. WhatsApp Marketing has emerged as a powerful tool in this quest, offering a direct and personalized messaging...",
    badges: ["AI-powered chatbots", "conversion rate optimization", "customer engagement",],
    url: "https://blogs.skalebot.com/2024/05/05/whatsapp-marketing-for-businesses-in-2024/"
  },
  {
    image: marketingImage2,
    publishBy: "Dinesh",
    publishTitle: "Supercharge Customer Support with Skalebot’s Multi-Account feature! ",
    publishDescription:
      "In today’s competitive business environment, exceptional customer support is crucial for retaining customers. Customers expect seamless interactions across all channels, and companies that fail to deliver risk losing their business..",
    badges: ["AI-powered chatbots", "customer service", "SMBs", "WhatsApp"],
    url: "https://blogs.skalebot.com/2024/05/05/skalebots-multi-account-feature/"
  },
  {
    image: marketingImage3,
    publishBy: "Alosia",
    publishTitle: "WhatsApp Broadcast: Automate Marketing with Skalebot",
    publishDescription:"In today’s digital age, businesses are constantly bombarded with marketing messages. Consumers are inundated with emails, social media ads, and website pop-ups. To stand out in this crowded landscape, businesses need to find ways to craft...",
    badges: ["customer service", "conversion rate optimization", "customer engagement",],
    url: "https://blogs.skalebot.com/2024/05/15/whatsapp-broadcast-automate-marketing-with-skalebot/"
  }
];
