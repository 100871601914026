import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import BussinessCards from "../BussinessCards/BussinessCards";
import Welcome from "../Welcome/Welcome";
import BusinessStrategy from "../BusinessStrategy/BusinessStrategy";
import VideoTour from "../VideoTour/VideoTour";
import Team from "../Team/Team";
import Marketing from "../Marketing/Marketing";
import PaymentPlan from "../PaymentPlan/PaymentPlan";
import FAQ from "../FAQ/FAQ";
import WhatsappContainer from "../WhatsappContainer/WhatsappContainer";
import Careers from "../Careers/Careers";
import Enquiry from "../Enquiry/Enquiry";

const HomePage = () => {
  const teamRef = useRef(null);
  const enquiryRef = useRef(null);
  const location = useLocation();

  const scrollToSection = (sectionRef) => {
    if (sectionRef.current) {
      const offsetTop = sectionRef.current.offsetTop;
      const navbarHeight = 100; 

      window.scrollTo({
        top: offsetTop - navbarHeight,
        behavior: "smooth"
      });
    }
  };

  const scrollToTeam = () => {
    scrollToSection(teamRef);
  };

  const scrollToEnquiry = () => {
    scrollToSection(enquiryRef);
  };

  // Use useEffect to check if there's a fragment identifier in the URL
  useEffect(() => {
    const fragment = location.hash.slice(1); // Get the fragment identifier without the #

    setTimeout(() => {
      if (fragment === "team") {
        scrollToTeam();
      } else if (fragment === "enquiry") {
        scrollToEnquiry(enquiryRef);
      }
    }, 1000);
  }, [location.hash]); // Run this effect whenever the hash changes
  return (
    <div>
      <Welcome />
      <BusinessStrategy />
      <BussinessCards />
      <VideoTour />
      <Marketing />
      <PaymentPlan />
      <section ref={teamRef} id="team">
        <Team />
      </section>
      <Careers />
      <FAQ />
      <WhatsappContainer />
      <section ref={enquiryRef} id="enquiry">
        <Enquiry />
      </section>
    </div>
  );
};

export default HomePage;
