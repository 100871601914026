import React, { useState } from "react";
import classes from "./careers.module.css";
import cardImg from "../../images/careersCard/cardImg1.png";
import { CareerData } from "../../data/careerData/Careersdata";
import CareerModal from "../../components/careerModal/CareerModal";

const Careers = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };
  return (
    <div className={classes.container} id="career" >
      <div className={classes.subContainer}>
        <h3 className={classes.heading}>Careers with skalebot</h3>
        <p className={classes.subHeading}>
          At Deskala, we see potential in every challenge and we foster an
          environment where your skills are not just appreciated but amplified,
          allowing you to reach new heights in your career. Deskala is more than
          a workplace; it's a vibrant community of diverse minds working
          together to create something extraordinary.
        </p>
        <button className={classes.button} onClick={handleShowModal}>
          Join us
        </button>
      </div>
      <div className={classes.secondContainer}>
        <h3 className={classes.cardHeading}>Why join us</h3>
        <div className={`${classes.scrollableContainer}`}>
          <div className={classes.cardContainer}>
            {CareerData.map((card, index) => {
              return (
                <div className={classes.card} key={index}>
                  <img
                    src={card.img}
                    alt="cardImg"
                    title="Card Image"
                    className={classes.cardImg}
                  />
                  <h3 className={classes.cardTitle}>{card.heading}</h3>
                  <p className={classes.cardDesc}>{card.desc}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {showModal && (
        <CareerModal
          title="Join us with Skalebot"
          description="Share your details to connect with our experts"
          messageBottom="Let us know your Level of expertise"
          showModal={showModal}
          onHideModal={handleHideModal}
        />
      )}
    </div>
  );
};

export default Careers;
