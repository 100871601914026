import React, { useState } from "react";
import "./App.css";
import Home from "./view/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChatbotModal from "./components/Chatbot/ChatbotModal";
import whatsappIcon from "./images/WhatsappIcon.svg";
import TermsNdConditions from "./view/Terms&Conditions/Terms&Conditions";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./view/Home/HomePage";
import { useNavigate, useLocation } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import PaymentPlan from "./view/PaymentPlan/PaymentPlan";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChatbotClick = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route
          path="/"
          element={
            <>
              <header>
                <Header />
              </header>
              <HomePage />
              <footer>
                <Footer />
              </footer>
              <ToastContainer />
              <div className="chatbot">
                <img
                  src={whatsappIcon}
                  alt="chatBotIcon"
                  title="chatBotIcon"
                  onClick={handleChatbotClick}
                />
              </div>
              {isModalOpen && (
                <div className="chatbot-modal-container">
                  <ChatbotModal
                    show={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                  />
                </div>
              )}
            </>
          }
        />
        <Route
          path="terms&conditions"
          element={
            <>
              <header>
                <Header />
              </header>
              <TermsNdConditions />
              <footer>
                <Footer />
              </footer>
              <ToastContainer />
              <div className="chatbot">
                <img
                  src={whatsappIcon}
                  alt="chatBotIcon"
                  title="chatBotIcon"
                  onClick={handleChatbotClick}
                />
              </div>
              {isModalOpen && (
                <div className="chatbot-modal-container">
                  <ChatbotModal
                    show={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                  />
                </div>
              )}{" "}
            </>
          }
        />
         <Route path="/pricing" element={ 
          <>
         <header>
         <Header />
       </header>
         <PaymentPlan />
         <footer>
                <Footer />
              </footer>
         </>
         } />
        {/* Catch-all route for handling invalid routes */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
