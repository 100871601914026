import React, { useState, useRef } from "react";
import "./chatbotModal.css";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import whatsappSendButton from "../../images/whatsappSendButton.svg";
import deskalaWhatsappIcon from "../../images/skaleBotWhatsappIcon.svg";

const ChatbotModal = ({ show, onClose }) => {
  const modalRef = useRef();
  const handleListItemClick = (content) => {
    setValue("message", content);
  };
  const defaultValues = {
    message: "Hi"
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm({ defaultValues });

  const sendWhatsAppMessage = (formData) => {
    const { message } = formData;

    const phoneNumber = "919492564750";
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

    // Open the WhatsApp URL in a new tab
    window.open(whatsappUrl, "_blank");
  };
  const submithandler = (data) => {
    sendWhatsAppMessage(data);
    setValue("message", "Hi");
    // onHideModal();
  };

  const cancelHandler = () => {
    setValue("message", "Hi");
    onClose();
  };
  const handleModalClick = (e) => {
    if (modalRef.current && modalRef.current.contains(e.target)) {
      e.stopPropagation(); // Prevent overlay click event inside the modal.
    }
  };

  return (
    <div>
      {show && (
        <div className="chatbotOverlay" onClick={onClose}>
          <div
            className=" custom-chatbot-modal"
            onClick={handleModalClick}
            ref={modalRef}
          >
            <div className=" botModalBody">
              <div className="headerContainer">
                <div className="d-flex flex-row align-items-center gap-2">
                  <img src={deskalaWhatsappIcon} alt="Deskala" 
                    title="whatsappicon"
                    loading="lazy"
                  />
                  <div className="d-flex flex-column gap-1">
                    <h3 className="deskala">SkaleBot</h3>
                    <h3 className="online">online</h3>
                  </div>
                </div>
                <h2 className="cancelIcon" onClick={cancelHandler}>
                  X
                </h2>
              </div>

              <div className="chat-content">
              <div className="messageContainer">
                  <div className="company"> SkaleBot</div>
                  <pre className="message">
                    Hi,
                    <br />
                    How can I help you ?
                  </pre>
                </div>
               
              </div>
              <div className=" bottomContainer "  onClick={handleSubmit(submithandler)} >
                <div className=" botInputContainer" >
                  <Controller
                    name="message"
                    control={control}
                    render={({ field, fieldState }) => (
                      
                        <InputText
                          id={field.name}
                          // disabled={!edit && mode === "update"}
                          value={field.value}
                          // onChange={(e) => field.onChange(e.target.value)}
                          locale="en-IN"
                          placeholder="Type a message..."
                          style={{
                            background: "transparent"
                          }}
                          className={` inputField
                     `}
                     disabled
                     onClick={handleSubmit(submithandler)}
                        />
                      
                    )}
                  />
                </div>
                {watch("message") && (
                  <img
                    src={whatsappSendButton}
                    alt="whatsappSendButton"
                    title="sendbutton"
                    loading="lazy"  
                    // onClick={handleSubmit(submithandler)}
                    className="cursor "
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotModal;
