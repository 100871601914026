import cardImg1 from "../../images/careersCard/cardImg1.jpg";
import cardImg2 from "../../images/careersCard/cardImg2.jpg";
import cardImg3 from "../../images/careersCard/cardImg3.jpg";

export const CareerData = [
  {
    img: cardImg1,
    heading: "Make a difference",
    desc: "Be a part of a team that is making a real impact. We are constantly innovating and expanding our reach."
  },
  {
    img: cardImg2,
    heading: "Be challenged",
    desc: "We are a fast-paced, dynamic company with a lot of room for growth. We are always looking for talented individuals who are not afraid to take on new challenges."
  },
  {
    img: cardImg3,
    heading: "Great colleagues",
    desc: "We have a team of some of the brightest minds in the industry. You will have the opportunity to learn from and collaborate with some of the best in the business."
  }
];
