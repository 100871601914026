import React, {useState} from "react";
import classes from "./welcome.module.css";
import appStoreSvg from "../../images/appStore.svg";
import playStoreSvg from "../../images/playStore.svg";
import qrCodeSvg from "../../images/qrCode.svg";
import phoneJgp from "../../images/phone.jpg";
import { welcomeCardsData } from "../../data/welcomeCards/welcomeCards";
import messagesicon from "../../images/welcome/salesDetailsIcon/messagesIcon.svg";
import salesEnquiryIcon from "../../images/welcome/salesDetailsIcon/salesEnquiryIcon.svg";
import servingicon from "../../images/welcome/salesDetailsIcon/servingIcon.svg";
import agentsicon from "../../images/welcome/salesDetailsIcon/agentsIcon.svg";
import rainbow from "../../images/welcome/rainbow.svg"

const Welcome = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const openWhatsAppChat = () => {
    const phoneNumber = "9492564750";
    const hiMessage = "Hi";
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${hiMessage}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div className={classes.container} id="home">
      <div className={classes.subContainer}>

      <div className={classes.welcomeSubContainer}>
        <div className={classes.headingContainer}>
          <h1 className={classes.heading}>
            Your Trusted Sales Assistant <br /> for{" "}
            <strong> Business Optimization </strong>
          </h1>
          <p className={classes.skalebotParagraph}>
          Skalebot is AI-based WhatsApp chatbot by Deskala that enables businesses to engage with their customers on WhatsApp.
          </p>
          {/* <p className={classes.skalebotParagraph}>
            Skalebot is an AI solution by Deskala that enables businesses to tap
            into the ecommerce market.
          </p> */}
          <div className={classes.qr}>
            {/* <WelcomeQr /> */}
            <img src={qrCodeSvg} alt="qr code"  title="QR code" />
          </div>
          <p className={classes.skalebotParagraph} style={{ fontWeight: 600 }}>
            Scan and experience the Skalebot in Whatsapp
          </p>
          <div className={classes.buttonContainer}></div>
        </div>


        <div className={classes.rainbow}>
          <img src={rainbow} alt="rainbow" className={classes.rainbowImg} title="Rainbow" />
        </div>
        <img src={phoneJgp} alt="phoneJgp" className={classes.phoneImg} title="Phone" />
      </div>

        <div className={classes.welcomeThirdSubContainer}>
        <div className={classes.thirdSubInsideContainer}>
          <div className={classes.statics}>
            <div className={classes.staticsIconContainer}>
              <img src={servingicon} alt="servingicon" title="servingicon" />
              <p className={classes.staticsNum}> 30+</p>
            </div>
            <p className={classes.staticsDesc}>Customers</p>
          </div>
          <div className={classes.statics}>
            <div className={classes.staticsIconContainer}>
              <img src={salesEnquiryIcon} alt="servingicon" title="Conversation"  />
              <p className={classes.staticsNum}> 5000+</p>
            </div>
            <p className={classes.staticsDesc}>Sales Enquires</p>
          </div>
          <div className={classes.statics}>
            <div className={classes.staticsIconContainer}>
              <img src={agentsicon} alt="servingicon" title="Agents" />
              <p className={classes.staticsNum}> 150+</p>
            </div>
            <p className={classes.staticsDesc}>Agents</p>
          </div>
          <div className={classes.statics}>
            <div className={classes.staticsIconContainer}>
              <img src={messagesicon} alt="servingicon" title="Messages" />
              <p className={classes.staticsNum}> 200000+</p>
            </div>
            <p className={classes.staticsDesc}>Conversation used</p>
          </div>
        </div>
        <div className={classes.mobileThirdSubInsideContainer}>
          <div className={classes.mobileStatics}>
            <p className={classes.staticsNum}> 30+</p>
            <p className={classes.staticsNum}> 5000+</p>
            <p className={classes.staticsNum}> 150+</p>
            <p className={classes.staticsNum}> 200000+</p>
          </div>
          <div className={classes.mobileStatics}>
            <img src={servingicon} alt="servingicon" title="servingicon"  />
            <img src={salesEnquiryIcon} alt="servingicon" title="Conversation"/>
            <img src={agentsicon} alt="servingicon" title="Agents"  />
            <img src={messagesicon} alt="servingicon" title="Messages" />
          </div>

          <div className={classes.mobileStaticsDesc}>
            <div className={classes.staticsIconContainer}>
              <p className={classes.staticsDesc}>Customers</p>
            </div>
            <div className={classes.staticsIconContainer}>
              <p className={classes.staticsDesc}>Sales Enquires</p>
            </div>
            <div className={classes.staticsIconContainer}>
              <p className={classes.staticsDesc}>Agents</p>
            </div>
            <div className={classes.staticsIconContainer}>
              <p className={classes.staticsDesc}>Conversation used</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className={classes.welcomeCardsContainer}>
        {welcomeCardsData.map((item, index) => {
          return (
            <div
              className={classes.welcomeCards}
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              {hoveredIndex !== index ? (
                <>
                  <img alt="img" src={item.icon} title="cardImage" />
                  <p className={classes.welcomeCardsTile}>{item.title}</p>
                </>
              ) : (
                <p className={classes.hoveredText}>{item.hoverText}</p>
              )}
              <img alt="img" src={item.bottom} className={classes.bottomImg} title="bottom img"/>
            </div>
          );
        })}
      </div>
      </div>
    </div>
  );
};

export default Welcome;
