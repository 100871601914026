import React, { useState, useEffect } from 'react';
import styles from "./growthPlan.module.css";
import { ReactComponent as RupeeSvg } from "../../../images/paymentPlan/rupeeWhite.svg";
import plansRightCheckSvg from "../../../images/paymentPlan/plansRightCheck1.jpg";
import { amounts, currencyIcons, growthPlanData,conversationTexts } from '../../../data/PaymentPlan/GrowthPlan';
import { ReactComponent as DollarSvg } from "../../../images/paymentPlan/dollarWhite.svg";

const GrowthPlan = () => {
    const [amount, setAmount] = useState("Loading");
    const [country, setCurrencyIcon] = useState(null);
    const [planData, setPlanData] = useState([]);
    const [conversationText, setConversationText] = useState("");

    useEffect(() => {
        const fetchPlanData = () => {
            const userLocation = localStorage.getItem('SkalebotuserLocation');
            if (userLocation) {
                const parsedLocation = JSON.parse(userLocation);
                const countryCode = parsedLocation.country === "IN" ? parsedLocation.country : 'otherCountry';
                setAmount(amounts[countryCode]);
                setCurrencyIcon(countryCode);
                setPlanData(growthPlanData[countryCode]);
                setConversationText(conversationTexts[countryCode]);
            } else {
                setTimeout(fetchPlanData, 1000);
            }
        };

        fetchPlanData();
    }, []);

    return (
        <div className={styles.basicPlancontainer} >
            <div className={styles.PlanNameContainer}>
                <p className={styles.PlanName} > Growth</p>
            </div>
            <div className={styles.pricingContainer} >
                <p className={styles.pricingdetails} > Perfect to jumpstart your journey on WhatsApp </p>
                <div className='d-flex align-items-center gap-1' >
                    {country === "IN" ? <RupeeSvg className={styles.rupee} /> : <DollarSvg className={styles.dollar} />}
                    <p className={styles.amount} >{amount}</p>
                </div>
                <p className={styles.pricingdetails} style={{ fontWeight: 800 }} >( Per Month )<br />  </p>
            </div>
            <div className={styles.conversationContainer} >
                <p className={styles.conversation} > {conversationText}</p>
            </div>
            <div className='d-flex justify-content-center' style={{ marginTop: "3px" }} >
                <div style={{ background: "#D9D9D9", width: "85%", height: "1px" }} ></div>
            </div>
            <div className={styles.serviceContainer} >
                <p className={`${styles.services} my-1 `} >Services Included</p>
                {planData.map((data, index) => (
                    <div className='d-flex align-items-start gap-2 my-2 ' key={index} >
                        <img src={plansRightCheckSvg} alt='plans' title='rightCheck' className={styles.plansCheck} />
                        <p className={styles.servicesListItem} >{data}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default GrowthPlan;
