import React from "react";
import classes from "./videotour.module.css";

const VideoTour = () => {
  return (
    <div className={classes.container} id="videoTour">
      <div className={classes.subContainer}>
        <h3 className={classes.title}>Curious About Skalebot ? </h3>
        <p className={classes.desc}>
          Watch our quick demo video ! Click to discover more.
        </p>
        <iframe
          width="560"
          height="315"
          // src="https://www.youtube.com/embed/c_Q_RwU57iM?si=uvVG2KeNbZJNfAeZ&rel=0"
          src="https://www.youtube.com/embed/nKbx6rAmbdQ?si=K1HbIN8sXffGpm5X"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className={classes.iframe}
        ></iframe>
      </div>
    </div>
  );
};

export default VideoTour;
