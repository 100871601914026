import React from "react";
// import "./style.css";
import classes from "./footer.module.css";
import { useNavigate, useLocation, Link, NavLink } from "react-router-dom";
import deskalaLogoWhiteSvg from "../../images/companyLogos/deskalaLogo.svg";
import fbLogoSvg from "../../images/socialLogos/facebookIcon.svg";
import twitterLogoSvg from "../../images/socialLogos/twitterIcon.svg";
import linkdinLogoSvg from "../../images/socialLogos/linkedinIcon.svg";
import youtubeLogoSvg from "../../images/socialLogos/youtubeIcon.svg";
import instagramLogoSvg from "../../images/socialLogos/instagramIcon.png";

const Footer = () => {
  const navigate = useNavigate();
  const handleClickScroll = (idName) => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById(idName);
      if (element) {
        // Calculate the offset based on the header's height
        const header = document.querySelector(`.${classes.footer}`);
        const footerHeight = header ? header.clientHeight : 0;
        const offset = element.offsetTop - (footerHeight - 140);

        window.scrollTo({
          top: offset,
          behavior: "smooth"
        });
      }
    }, 300);
  };

  return (
    <>
      <div className={`${classes.footer}`}>
        <div className="w-75">
          <div className="d-flex flex-column mb-3 ">
            <p className={classes.powerdby}>Powered by</p>
            <img
              src={deskalaLogoWhiteSvg}
              alt="deskala-logo"
              title="deskala-logo"
              className={classes.deskalaLogo}
            />

            <p className={classes.deskalaWebsite}>
              Visit our website{" "}
              <a
                href="https://www.deskala.in/"
                className={classes.deskalaAnchorTag}
                target="_blank"
                rel="noreferrer"
              >
                www.deskala.in
              </a>
            </p>
          </div>

          <div className="d-flex flex-wrap flex-md-row  justify-content-start  col-lg-12  ">
            <div className="d-flex flex-column flex-lg-column justify-content-center justify-content-xl-start justify-content-sm-center align-items-start col-6 col-xl-3   ">
              <h4
                className={classes.linkTitle}
                onClick={() => handleClickScroll("home")}
              >
                About
              </h4>
              <h4
                className={classes.linkTitle}
                onClick={() => handleClickScroll("videoTour")}
              >
                Video tour
              </h4>
              <h4
                className={`${classes.linkTitle} d-block d-lg-none  `}
                onClick={() => {
                  navigate("/terms&conditions");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                  });
                }}
              >
                Terms & Conditions
              </h4>
            </div>
            <div className="d-flex flex-column flex-lg-column  justify-content-lg-start justify-content-sm-center align-items-start col-6 col-xl-3  ">
              <h4 className={classes.linkText}>
                <a href="/pricing" className={classes.linkText} tabIndex={0}>
                Pricing
                </a>
              </h4>
              <h4
                className={classes.linkTitle}
                onClick={() => handleClickScroll("career")}
              >
                Career
              </h4>
              <h4
                className={`${classes.linkTitle} d-none`}
                onClick={() => handleClickScroll("team")}
              >
                Our Team
              </h4>
              <h4
                className={`${classes.linkTitle} d-block d-lg-none`}
                onClick={() => handleClickScroll("enquiry")}
              >
                Enquiry
              </h4>
            </div>
            <div className=" d-none d-lg-flex flex-column flex-lg-column  justify-content-lg-start justify-content-sm-center align-items-start col-6 col-xl-3  ">
              <h4
                className={classes.linkTitle}
                onClick={() => handleClickScroll("enquiry")}
              >
                Enquiry
              </h4>
              <h4
                className={`${classes.linkTitle}`}
                onClick={() => {
                  navigate("/terms&conditions");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                  });
                }}
              >
                Terms & Conditions
              </h4>
            </div>

            <div
              className={`d-flex flex-wrap flex-row  justify-content-xl-end  gap-3 col-7 col-xl-3 ${classes.mobileRes} `}
            >
              <a
                href="https://www.youtube.com/@skaleworkskalebot"
                className=" cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={youtubeLogoSvg}
                  alt="youtubeLogoSvg"
                  title="youtubeLogo"
                  loading="Lazy"
                  href="https://www.youtube.com/@skaleworkskalebot"
                  className={classes.socialShareLogo}
                />
              </a>
              {/* <a
                href="https://www.facebook.com/profile.php?id=100057829712479"
                className=" cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={fbLogoSvg}
                  alt="fbLogo"
                  title="fbLogo"
                  className={classes.socialShareLogo}
                />
              </a> */}
              <a
                href="https://www.linkedin.com/company/skalebot"
                className=" cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linkdinLogoSvg}
                  alt="linkdinLogo"
                  title="linkdinLogo"
                  loading="Lazy"
                  href="https://www.linkedin.com/company/skalebot"
                  className={classes.socialShareLogo}
                />
              </a>
              <a
                href="https://www.instagram.com/skalebot/"
                className=" cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={instagramLogoSvg}
                  alt="instagramLogo"
                  title="instagramLogo"
                  loading="Lazy"
                  href="https://www.instagram.com/skalebot/"
                  className={classes.socialShareLogo}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.copyrightsContainer}>
        <p className={classes.copyrightsText}>
          {/* @ 2023 &copy; Copyrights. All rights reserved */}
          Copyrights &copy; 2023 Deskala Research and Design Consulting Pvt. Ltd
        </p>
      </div>
    </>
  );
};

export default Footer;
