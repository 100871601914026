import React, { useState, useEffect } from 'react';
import styles from "./standardPlan.module.css"
import { ReactComponent as RupeeSvg } from "../../../images/paymentPlan/rupeeWhite.svg"
import plansRightCheckSvg from "../../../images/paymentPlan/plansRightCheck1.jpg";
import { amounts, StandardPlanData, conversationDetails, additionalUserLicenseDetails } from '../../../data/PaymentPlan/StandardPlanData';
import { ReactComponent as DollarSvg } from "../../../images/paymentPlan/dollarWhite.svg";

const StandardPlan = () => {
    const [amount, setAmount] = useState("Loading");
    const [country, setCurrencyIcon] = useState(null);

    useEffect(() => {
        const fetchAmount = () => {
            const userLocation = localStorage.getItem('SkalebotuserLocation');
            if (userLocation) {
                const parsedLocation = JSON.parse(userLocation);
                const countryCode = parsedLocation.country === "IN" ? parsedLocation.country : 'otherCountry';
                setAmount(amounts[countryCode]);
                setCurrencyIcon(countryCode);
            } else {
                setTimeout(fetchAmount, 1000);
            }
        };

        fetchAmount();
    }, []);

    // Ensure StandardPlanData[country] is defined before mapping over it
    const serviceItems = StandardPlanData[country] || [];

    return (
        <div className={styles.standardPlanContainer} >
            <div className={styles.PlanNameContainer}>
                <p className={styles.PlanName} > Standard</p>
            </div>
            <div className={styles.pricingContainer} >
                <p className={styles.pricingdetails} > Ideal for growing teams for better customer engagement </p>
                <div className='d-flex align-items-center  gap-1' >
                    {country === "IN" ? <RupeeSvg className={styles.rupee} /> : <DollarSvg className={styles.dollar} />}
                    <p className={styles.amount} >{amount}</p>
                </div>
                <p className={styles.pricingdetails} style={{ fontWeight: 700 }} >( Per Month )</p>
            </div>
            <div className={styles.conversationContainer} >
                <p className={styles.conversation} >{conversationDetails[country]}</p>
                <p className={styles.rechargeText}> Additional users licence <br/><span style={{ fontWeight: 500 }} >{additionalUserLicenseDetails[country]} </span></p>
            </div>
            <div className='d-flex justify-content-center'>
                <div style={{ background: "#D9D9D9", width: "85%", height: "1px" }} ></div>
            </div>
            <div className={styles.serviceContainer} >
                <p className={`${styles.services} my-1 `} >Services Included</p>
                <div className='d-flex align-items-start gap-2 mb-2 ' >
                    <p className={styles.servicesListItem} >everything in Growth +</p>
                </div>
                {serviceItems.map((data, index) => (
                    <div className='d-flex align-items-start gap-2 my-2 ' key={index} >
                        <img src={plansRightCheckSvg} alt='plans' title='RightCheck' className={styles.plansCheck} />
                        <p className={styles.servicesListItem} >{data}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default StandardPlan;
