import React from "react";
import classes from "./enquiry.module.css";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import mailboxSvg from "../../images/Mailbox.svg";

const Enquiry = () => {
  const defaultValues = {
    name: "",
    email: "",
    number: ""
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="text-danger">{errors[name].message}</small>
      )
    );
  };

  const sendWhatsAppMessage = (formData) => {
    const { name, email, number } = formData;
    const phoneNumber = "918878183433";
    const messageText = `Name: ${name}%0A Mail Id: ${email}%0A Mobile no: ${number}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageText}`;

    // Open the WhatsApp URL in a new tab
    window.open(whatsappUrl, "_blank");
  };

  const submithandler = (data) => {
    sendWhatsAppMessage(data);
    reset();
  };
  return (
    <div className={classes.container}>
      <div className={classes.contactUsContainer} id="enquiry">
        <h3 className={classes.contactUsTitle}>
          Learn more, arrange a call back!
        </h3>
        <div className={classes.subContainer}>
          <div className={classes.imgContainer}>
            <img src={mailboxSvg} alt="mailbox" title="Mail box" />
          </div>
          <div className={`${classes.formContainer}`}>
            <div className={`${classes.inputDiv} text-start `}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Please provide name",
                  pattern: {
                    value: /^[A-Za-z .'-]+$/,
                    message: "Enter a valid name"
                  }
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    // disabled={!edit && mode === "update"}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    //   useGrouping={false}
                    locale="en-IN"
                    placeholder="Your Name"
                    style={{
                      background: "transparent"
                    }}
                    className={`
                    ${classes.inputStyle}
                     ${fieldState.invalid ? "border border-danger" : ""}
                  `}
                  />
                )}
              />
              {getFormErrorMessage("name")}
            </div>
            <div className={`${classes.inputDiv} text-start `}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Please provide email",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Enter a valid email id"
                  }
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    // disabled={!edit && mode === "update"}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    //   useGrouping={false}
                    locale="en-IN"
                    placeholder="Your Email"
                    style={{
                      background: "transparent"
                    }}
                    className={`
                  ${classes.inputStyle}
                  ${fieldState.invalid ? "border border-danger" : ""}
                `}
                  />
                )}
              />
              {getFormErrorMessage("email")}
            </div>
            <div className={`${classes.inputDiv} text-start `}>
              <Controller
                name="number"
                control={control}
                rules={{
                  required: "Please provide mobile number",
                  pattern: {
                    value: /^[0-9]{10}$/g,
                    message: "Enter a valid mobile number"
                  }
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    // disabled={!edit && mode === "update"}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    //   useGrouping={false}
                    locale="en-IN"
                    placeholder="Your Mobile number."
                    style={{
                      background: "transparent"
                    }}
                    className={`
                  ${classes.inputStyle}
                  ${fieldState.invalid ? "border border-danger" : ""}
                `}
                  />
                )}
              />
              {getFormErrorMessage("number")}
            </div>
            <button
              className={classes.submitButton}
              onClick={handleSubmit(submithandler)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
