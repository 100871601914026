import React, { useState, useEffect, useMemo } from "react";
import classes from "./header.module.css";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import skalebotLogo from "../../images/companyLogos/skaleBotLogo.svg";

import { ReactComponent as HamburgerSvg } from "../../images/hamburger.svg";

const MenuItem = ({ label, id, onClick, isActive }) => (
  <Button
    label={label}
    link
    className={`${classes.buttonTitle} ${isActive && id ? classes.active : ""}
    `}
    onClick={() => {
      onClick(id);
    }}
  />
);

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isHamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const onClose = () => {
    setHamburgerMenuOpen(false);
  };

  const toggleHamburgerMenu = () => {
    setHamburgerMenuOpen(!isHamburgerMenuOpen);
  };
  const handleClickScroll = (idName) => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById(idName);
      if (element) {
        // Calculate the offset based on the header's height
        const header = document.querySelector(`.${classes.header}`);
        const headerHeight = header ? header.clientHeight : 0;
        const offset = element.offsetTop - headerHeight;

        window.scrollTo({
          top: offset,
          behavior: "smooth"
        });
      }
    }, 300);
  };

  const menuItems = useMemo(
    () => [
      { label: "Home", id: "home" },
      { label: "Resources", id: "marketing" },
      { label: "Video tour", id: "videoTour" },
      { label: "Pricing", id: "paymentPlan" },
      { label: "Enquiry", id: "enquiry" }
    ],
    []
  );

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/") {
      setActiveMenuItem("home");
    } else {
      // Find the matching menu item based on the URL segment.
      const matchedMenuItem = menuItems.find((item) =>
        pathname.startsWith(`/${item.id}`)
      );

      if (matchedMenuItem) {
        setActiveMenuItem(matchedMenuItem.id);
      } else {
        setActiveMenuItem(null);
      }
    }
  }, [location.pathname, menuItems]);

  return (
    <div className={classes.header}>
      <img
        src={skalebotLogo}
        title="skalebotLogo"
        loading="lazy"
        alt="skalebotLogo"
        className={classes.logo}
        onClick={() => handleClickScroll("home")}
      />

      <div className={classes.navbarMenu}>
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.id}
            label={menuItem.label}
            id={menuItem.id}
            onClick={() => handleClickScroll(menuItem.id)}
            isActive={menuItem.id === activeMenuItem}
          />
        ))}
        <a href="https://blogs.skalebot.com/"  target="_blank" rel="noopener noreferrer" className={`${classes.buttonTitle}` } style={{textDecoration:"none", padding:"5px", borderRadius:"6px"}}  >
        <p className="m-0" >Blogs</p>
        </a>
        <div style={{height:"20px", width:"1px", background:"#888888"}} ></div>
        <a href="https://app.skalebot.com/login"  target="_blank" rel="noopener noreferrer" className={`${classes.buttonTitle}` } style={{textDecoration:"none", border:"1px solid black", padding:"5px", borderRadius:"6px"}}  >
        <p className="m-0" >Sign up with Skalebot</p>
        </a>
      </div>
      <HamburgerSvg
        onClick={toggleHamburgerMenu}
        className={classes.hamburger}
      />

      {isHamburgerMenuOpen && (
        <div className={`${classes.hamburgerMenu} ${classes.open}`}>
          <div className={classes.hamburgerMenuContent}>
            {menuItems.map((menuItem) => (
              <MenuItem
                key={menuItem.id}
                label={menuItem.label}
                id={menuItem.id}
                onClick={() => {
                  handleClickScroll(menuItem.id);
                  onClose();
                }}
                isActive={menuItem.id === activeMenuItem}
              />
            ))}
            <a href="https://blogs.skalebot.com"  target="_blank" rel="noopener noreferrer"  onClick={()=>onClose()} className={`${classes.buttonTitle}` } style={{textDecoration:"none",}}  >
            <p className={`${classes.buttonTitle}` }>Blogs</p>
            </a>
            <a href="https://app.skalebot.com/login"  target="_blank" rel="noopener noreferrer"  onClick={()=>onClose()} className={`${classes.buttonTitle}` } style={{textDecoration:"none",}}  >
            <p className={`${classes.buttonTitle}` }>Sign up with Skalebot</p>
            </a>
            <div className={classes.cancelIcon} onClick={toggleHamburgerMenu}>
              <h2>X</h2>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
