import React from "react";
import classes from "./paymentPlan.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import GrowthPlan from "../../components/Plans/GrowthPlan/GrowthPlan";
import StandardPlan from "../../components/Plans/StandardPlan/StandardPlan";
import ProPlan from "../../components/Plans/ProPlan/ProPlan";
import CustomPlan from "../../components/Plans/CustomPlan/CustomPlan";
import { Helmet } from 'react-helmet-async';
const onbordinglistData = [
  "Onboarding fee covers Facebook page setup and verification authenticated by Meta and WhatsApp, Skalebot registration, and one-year maintenance.",
  `Unrestricted backend data uploads for customers.`,
  `Tailor business flow to specific scenarios.`,
  `Form multiple user groups with distinct categories.`,
  `Initiate business messages with pre-designed advertising template, and get a business website landing page from Skalebot.`
];

const PaymentPlan = () => {
  const navigate = useNavigate();
  const handleClickScroll = (idName) => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById(idName);
      if (element) {
        // Calculate the offset based on the header's height
        const header = document.querySelector(`.${classes.header}`);
        const headerHeight = header ? header.clientHeight : 0;
        const offset = element.offsetTop - (headerHeight + 80);

        window.scrollTo({
          top: offset,
          behavior: "smooth"
        });
      }
    }, 300);
  };
  return (
    <div className={classes.container} id="paymentPlan">
        <Helmet>
                <title>Skalebot Pricing </title>
                <link rel="canonical" href="https://www.skalebot.com/pricing" />
                <meta name="description" content="Skalebot Pricing" />
                <meta property="og:title" content="Skalebot Pricing - Craft a personalized experience with our custom plan" />
                <meta property="og:description" content="Craft a personalized experience with our custom plan. Designed for your unique goals." />
                <meta property="og:image" content="https://www.skalebot.com/static/media/skaleBotLogo.e6910475703412b239dded1f2e19c2a8.svg" />
            </Helmet>
      <div className={classes.subContainer}>
        <h3 className={classes.heading}>Select your plan</h3>
        <h3 className={classes.subHeading}>
          Choose a plan that’s right for you
        </h3>
        {/* <div className={`${classes.scrollableContainer}`}> */}
        <div className={classes.plansContainer}>
         
          <GrowthPlan/>
          <StandardPlan/>
          <ProPlan/>
          <CustomPlan/>
        </div>
        {/* </div> */}
        <div className={classes.onboardingContainer}>
          {/* <h3 className={classes.onboardingHeading}>
            On-boarding Perks and Services
          </h3> */}
          <div className={classes.listContainer}>
            {/* <ol className={classes.customOl}>
              {onbordinglistData.map((list, index) => {
                return (
                  <li className={classes.listItem} key={index}>
                    {list}
                  </li>
                );
              })}
            </ol> */}

            {/* <div className={classes.questionmarkcontainer}>
              <img
                src={questionMarkSvg}
                alt="questionMarkSvg"
                className={classes.questionMark}
              />
              <button
                className={classes.button}
                onClick={() => handleClickScroll("team")}
              >
                Contact sales
              </button>
            </div> */}
          </div>
          <div className={classes.noteContainer}>
            {/* <h3 className={classes.noteHeading}>Note</h3> */}
            <div className={classes.noteSubContainer}>
              <h3 className={classes.note}>
              *All messages between a business and user within 24 hours time will be counted as one conversation
              </h3>
              <h3 className={classes.note}>
              *The fee remains consistent for text, media & documents regardless of the message type.
              </h3>
              <h3 className={classes.note}>
              *The started prices are exclusive of an additional 18% GST
                charge.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPlan;
