import alosiaSvg from "../../images/Team/alosia.svg";
import goldwinPng from "../../images/Team/goldwin.png";
import rojaPng from "../../images/Team/roja.png";

export const TeamData = [
  {
    name: "Alosia Rose",
    position: "Marketing Head",
    mobileNo: "+91  9847536687",
    mailId: "alosia.rose@deskala.in",
    img: alosiaSvg
  },
  {
    name: "Goldwin Paul Kavala",
    position: "Sr. Business Development Manager",
    mobileNo: "+91  9490337682",
    mailId: "goldwin.paul@deskala.in",
    img: goldwinPng
  },
  {
    name: "Chiruvolu Roja ",
    position: "Sr. Business Development Manager",
    mobileNo: "+91  9113041388",
    mailId: "chiruvolu.roja@deskala.in",
    img: rojaPng
  }
];
