import React from "react";
import classes from "./bussinessCards.module.css";
import { bussinessCardsData } from "../../data/bussinessCards";

const BussinessCards = () => {
  return (
    <div className={classes.container} id="businessCards">
      <div className={classes.benifitsContainer}>
        <h2 className={classes.benifitsSubHeading}>
          Optimize, Scale, and Succeed
        </h2>
        <h2 className={classes.benifitsHeading}>
          The Only Chatbot Your Business Needs
        </h2>
        <div className={`${classes.scrollableContainer}`}>
          <div className={`${classes.cardsContainer} `}>
            {bussinessCardsData.map((item, index) => {
              return (
                <div key={index} className={classes.cardWraper}>
                  <div className={`${classes.customCard} gap-2`}>
                    <img
                      src={item.image}
                      alt={`item-${index}`}
                      className="mb-2"
                      title={`CardTitle-${index}`}
                    />
                    <h3 className={classes.cardTitle}>{item.title}</h3>
                    <p className={classes.cardDescription}>
                      {item.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BussinessCards;
