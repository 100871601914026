import React from "react";
import classes from "./team.module.css";
import alosiaSvg from "../../images/Team/alosia.svg";
import phoneSvg from "../../images/socialLogos/phone.svg";
import mailSvg from "../../images/socialLogos/mail.svg";
import { TeamData } from "../../data/teamData/TeamData";

const Team = () => {
  return (
    <div className={classes.container} id="team">
      <div className={classes.subContainer}>
        <h3 className={classes.heading}>Our sales team</h3>
        <h3 className={classes.subHeading}>
          We are here to help with any questions
        </h3>
        <div className={`${classes.scrollableContainer}`}>
          <div className={classes.cardContainer}>
            {TeamData.map((item, index) => {
              return (
                <div className={classes.card} key={index}>
                  <img
                    src={item.img}
                    alt={`alosiaSvg`+index}
                    title={item.name}
                    className={classes.cardImg}
                  />
                  <h3 className={classes.cardName}>{item.name}</h3>
                  <h3 className={classes.cardPosition}>{item.position}</h3>
                  <div className={classes.contactDetailsContainer}>
                    <div className={classes.contactContainer}>
                      <img src={phoneSvg} alt="phoneSvg" title="Phone" />
                      <p className={classes.contactDetails}>{item.mobileNo}</p>
                    </div>
                    <div className={classes.contactContainer}>
                      <img src={mailSvg} alt="mailSvg" title="Mail" />
                      <p className={classes.contactDetails}>{item.mailId}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
