import React from "react";
import classes from "./businessStrategy.module.css";
import rightCheckSvg from "../../images/rightCheck.svg";
import desktopImg from "../../images/desktopNdPhone.png";
import { StrategyList } from "../../data/strategyList/StrategyList";

const BusinessStrategy = () => {
  return (
    <div className={classes.container} id="businessStrategy">
      <div className={classes.subContainer}>
        <p className={classes.title}>
          {" "}
          Conversations Redefined | Growth Simplified
        </p>
        <div className={classes.descContainer}>
          <div className={classes.descDetailsContainer}>
            <p className={classes.desc}>
              {" "}
              Skalebot effortlessly guides you in establishing a strong online
              presence. Connecting with potential customers becomes seamless
              through WhatsApp, a platform trusted by billions worldwide.
            </p>
            <div className={classes.benifitsContainer}>
              {StrategyList.map((item, index) => {
                return (
                  <div className={classes.benifitsDetails} key={index}>
                    <img src={rightCheckSvg} alt="rightCheck" title='RightCheck' />
                    <p className={classes.benifits}>{item.desc} </p>
                  </div>
                );
              })}
            </div>
          </div>
          <img src={desktopImg} alt="desktopImg" title="DesktopImg" className={classes.image} />
        </div>
      </div>
    </div>
  );
};

export default BusinessStrategy;
