export const FAQData = [
  {
    id: 1,
    title: "General",
    questions: [
      {
        id: 1,
        text: "Is Skalebot adaptable for businesses, regardless of their size?",
        answer:
          "Yes, Skalebot is highly scalable and can efficiently manage dozens of queries simultaneously, even during peak times. It eliminates the need for a large team, making it a practical solution for businesses of all sizes."
      },
      {
        id: 2,
        text: "Is Skalebot available 24/7 for my customers, and how does this benefit my business?",
        answer:
          "Yes, Skalebot operates round the clock, providing instant responses anytime. This ensures that your business is always available to potential customers, boosting customer satisfaction and potentially increasing sales."
      },
      {
        id: 3,
        text: "Is there a learning curve for using Skalebot, and do you provide training and support if I encounter issues with Skalebot?",
        answer:
          "Skalebot is user-friendly, and we provide comprehensive training and materials to help you get started quickly. Our support team is also available to assist you with any questions or concerns. "
      }
    ]
  },
  {
    id: 2,
    title: "Services",
    questions: [
      {
        id: 1,
        text: "Is there a limit to the number of products that Skalebot can handle in the catalog?",
        answer:
          "As of now, Skalebot does not impose any limits on the number of products in the catalog. Enjoy the flexibility to scale your catalog without constraints.  "
      },
      {
        id: 2,
        text: "What type of analytics and reporting does Skalebot campaigns feature have?",
        answer:
          "Skalebot provides detailed analytics and reporting, giving you insights into customer interactions like enquiries, and overall performance of campaigns. This data empowers you to make informed decisions and optimize your sales strategy."
      },
      {
        id: 3,
        text: "How can Skalebot Sales support feature work? ",
        answer:
          "Skalebot's sales support feature enables customers to seamlessly connect with pre/post sales agents/ customer support, providing in-depth information for a personalized touch and comprehensive support. Sales agents are equipped to initiate follow-up conversations, enhancing the overall customer experience. This dynamic feature not only addresses specific queries but also fosters ongoing satisfaction, ensuring a proactive and customer-centric approach."
      },
      {
        id: 4,
        text: "What kind of onboarding support is available for businesses integrating Skalebot into their operations?",
        answer:
          "We provide comprehensive onboarding support, including documentation, tutorials, and responsive customer support to ensure a smooth integration process for your business. "
      },
      {
        id: 5,
        text: "Is there a limit to the number of users or agents who can interact with Skalebot simultaneously?",
        answer:
          "Skalebot is scalable and can efficiently manage interactions with multiple users or agents simultaneously. There's no strict limit, making it suitable for businesses of varying sizes. "
      },
      {
        id: 6,
        text: "How frequently does Skalebot update its features, and will I have access to new features?",
        answer:
          " Skalebot is continuously evolving, and we regularly update its features to provide the latest advancements. As a user, you will have access to new features and improvements as they are released. "
      }
    ]
  },
  {
    id: 3,
    title: "Pricing",
    questions: [
      {
        id: 1,
        text: "How is Skalebot priced?",
        answer:
          "Skalebot offers different pricing plans for Retail, MSME, and Enterprise sectors. There is a one-time onboarding fee for each sector, and subsequent pricing varies based on the chosen plan."
      },
      {
        id: 2,
        text: "Is there a limit on the number of processes I can automate?",
        answer:
          "The limit on processes depends on the chosen plan. Retail, MSME, and Enterprise plans offer different levels of conversations or QR barcode scans, catering to the needs of various business sizes."
      },
      {
        id: 3,
        text: "Can Skalebot be customized to match my brand's voice and style?",
        answer:
          "Absolutely. Skalebot can be customized to align with your brand's voice and style, providing a personalized and cohesive experience for your customers. Please refer to our pricing for more information"
      },
      {
        id: 4,
        text: "What happens if I exceed the included QR code scans or conversations in my chosen plan?",
        answer:
          "If you exceed the allocated QR code scans or conversations in your plan, you may have the option to purchase additional conversations. "
      }
    ]
  }

  // Add more topics as needed
];
