import React from "react";
import classes from "./whatsappContainer.module.css";
import whatsappQrPhoneSvg from "../../images/whatsappQrPhone.png";
import appStoreSvg from "../../images/appStore.svg";
import playStoreSvg from "../../images/playStore.svg";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";

const WhatsappContainer = () => {
  const defaultValues = {
    name: "",
    email: ""
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="text-danger">{errors[name].message}</small>
      )
    );
  };

  const sendWhatsAppMessage = (formData) => {
    const { name, email } = formData;
    const phoneNumber = "918878183433";
    const messageText = `Name: ${name}%0A email Id: ${email}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${messageText}`;

    // Open the WhatsApp URL in a new tab
    window.open(whatsappUrl, "_blank");
  };

  const submithandler = (data) => {
    sendWhatsAppMessage(data);
    setValue("name", "");
    setValue("email", "");
  };
  return (
    <div className={classes.container} id="whatsappContainer">
      <div className={classes.subContainer}>
        <div className={classes.whatsappContainer}>
          <img src={whatsappQrPhoneSvg} alt="whatsappQrCodeSvg" title="whatsapp QR code"  className={classes.DeskImg}/>
          <div className={classes.qrDetails}>
            <h3 className={classes.qrTitle}>
              Catch Skalebot
              <br />
              live in action!
            </h3>
            <h3 className={classes.qrSubTitle}>
              Scan the QR Code to experience <br /> our WhatsApp chatbot.
            </h3>
            <img src={whatsappQrPhoneSvg} alt="whatsappQrCodeSvg" title="whatsapp QR code"   className={classes.mobileImg}  />
            <p className={classes.qrmobileTitle} id="storeLink">
              TRY SKALEBOT ON MOBILE
            </p>
            <div className={`${classes.storeContainer} gap-2`}>
              {/* <img
                src={appStoreSvg}
                alt="appStoreSvg"
                className="cursor-pointer"
              /> */}
              <a href="https://play.google.com/store/apps/details?id=com.skalebot.app&hl=en&gl=US" target="_blank" rel="noopener noreferrer">
              <img
                src={playStoreSvg}
                alt="playStore"
                title="Play Store"
                className="cursor-pointer"
              />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsappContainer;
