import React, { useState } from "react";
import classes from "./faq.module.css";
import { FAQData } from "../../data/FAQData/FaqData";

const FAQ = () => {
  const [activeTopic, setActiveTopic] = useState(1);
  const [expandedQuestions, setExpandedQuestions] = useState([]);

  const handleTopicChange = (e) => {
    const selectedTopicId = parseInt(e.target.value);
    setActiveTopic(selectedTopicId);
    setExpandedQuestions([]);
  };

  const handleQuestionClick = (questionId) => {
    setExpandedQuestions((prevExpanded) =>
      prevExpanded.includes(questionId)
        ? prevExpanded.filter((id) => id !== questionId)
        : [questionId]
    );
  };

  return (
    <div className={classes.container} id="faq">
      <div className={classes.subContainer}>
        <h3 className={classes.heading}>Got Questions? Find Answers here!</h3>
        {/* <h3 className={classes.subHeading}>
          Can't find your question? Call us
        </h3> */}
        <div className={classes.faqContainer}>
          <div className={classes.leftFaqContainer}>
            <h3 className={classes.topicHeading}>Table of contents</h3>

            <select
              className={` ${classes.topicDropdown}`}
              onChange={handleTopicChange}
            >
              {FAQData.map((topic) => (
                <option key={topic.id} value={topic.id}>
                  {topic.title}
                </option>
              ))}
            </select>

            <div className={classes.topics}>
              {FAQData.map((topic) => (
                <div
                  key={topic.id}
                  className={`${classes.topic} ${
                    activeTopic === topic.id ? classes.active : ""
                  }`}
                  onClick={() => setActiveTopic(topic.id)}
                >
                  {topic.title}
                </div>
              ))}
            </div>
          </div>
          <div className={classes.questions}>
            {FAQData.find((topic) => topic.id === activeTopic).questions.map(
              (question) => (
                <div key={question.id} className={classes.question}>
                  <div
                    className={classes.questionHeader}
                    onClick={() => handleQuestionClick(question.id)}
                  >
                    <div className={classes.toggleIcon}>
                      {expandedQuestions.includes(question.id) ? "-" : "+"}
                    </div>
                    <div className={classes.questionText}>{question.text}</div>
                  </div>
                  {expandedQuestions.includes(question.id) && (
                    <div className={classes.answer}>{question.answer}</div>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
