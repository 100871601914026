import { ReactComponent as RupeeSvg } from "../../images/paymentPlan/rupeeWhite.svg";
import { ReactComponent as DollarSvg } from "../../images/paymentPlan/dollarWhite.svg";

export const amounts = {
    IN: "999",
    otherCountry: 12
};

export const currencyIcons = {
    IN: RupeeSvg,
    otherCountry: DollarSvg
};

export const growthPlanData = {
    IN: [
        "One time 1000 free chatbot conversation",
        "Whatsapp business API & Integration",
        "Lead generation, capture, and tracking",
        "Whatsapp Catalogue",
        "WhatsApp Broadcast - Analytics upto 5 Campaigns",
        "One bot flow",
        "Bulk product & customer upload",
    ],
    otherCountry: [
        "One time 1000 free chatbot conversation",
        "Whatsapp business API & Integration",
        "Lead generation, capture, and tracking",
        "Whatsapp Catalogue",
        "WhatsApp Broadcast - Analytics upto 5 Campaigns",
        "One bot flow",
        "Bulk product & customer upload",
    ]
};

export const conversationTexts = {
    IN: "Conversation charges: As per Meta rates",
    otherCountry: "Conversation charges: As per Meta rates"
};
