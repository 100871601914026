import React, { useState, useRef } from "react";
import classes from "./careerModal.module.css";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader/Loader";

const CareerModal = ({
  title,
  description,
  messageBottom,
  showModal,
  onHideModal
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const fileInputRef = useRef(null);
  const defaultValues = {
    name: "",
    email: "",
    mobileNo: "",
    position: "",
    message: "",
    resume: null
  };
  const {
    control,
    formState: { errors, isSubmitted },
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className="text-danger">{errors[name].message}</small>
      )
    );
  };
  const notifySuccess = () => {
    toast.success("Thank you for applying!", {
      position: toast.POSITION.TOP_RIGHT,
      style: {
        background: "#C8F0B3",
        color: "#000",
        width: "300px",
        position: "absolute",
        top: "0",
        right: "0"
      }
    });
  };

  const notifyError = () => {
    toast.error("Error submitting the form. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
      style: {
        background: "#F08585",
        color: "#000",
        width: "300px",
        position: "absolute",
        top: "0",
        right: "0"
      }
    });
  };

  const submitFormDataToLiveAPI = async (formData) => {
    setSubmitting(true);
    try {
      const formDataWithResume = new FormData();
      formDataWithResume.append("to", "careers@skalebot.com");
      formDataWithResume.append(
        "subject",
        "Profile Submission From Careers Page"
      );
      formDataWithResume.append("name", formData.name);
      formDataWithResume.append("mobile", formData.mobileNo);
      formDataWithResume.append("message", formData.message);
      formDataWithResume.append("position", formData.position);
      formDataWithResume.append("resume", formData.resume);

      const response = await axios.post(
        "https://api-test.skalebot.com/api/v1/email/send-email",
        formDataWithResume
      );
      // console.log("Form submitted successfully to live API:", response.data);

      // Show success toast
      notifySuccess();
    } catch (error) {
      // console.error("Error submitting form to live API:", error);

      // Show error toast
      notifyError();
    } finally {
      setSubmitting(false);
    }
  };

  // Main submitHandler
  const submitHandler = async (formData) => {
    // e.preventDefault();
    try {
      setSubmitting(true);
      const isValid = await trigger();

      if (isValid && watch("resume")) {
        await submitFormDataToLiveAPI(formData);
        // console.log("formdata from submitHandler", formData);

        // Clear form values
        reset();

        // Clear the file input using the ref
        fileInputRef.current.value = "";

        // Close the modal
        onHideModal();
        // notifySuccess();
      } else {
        // console.log("Form is not valid. Please correct the errors.");
      }
    } catch (error) {
      // Handle error
      // console.error("Error in submitHandler:", error);
    } finally {
      // Enable the submit button and hide loading spinner
      setSubmitting(false);
    }
  };

  const cancelHandler = () => {
    reset();
    fileInputRef.current.value = "";
    onHideModal();
  };
  const handleFileChange = (e) => {
    // Update the form value when a file is selected
    setValue("resume", e.target.files[0]);
  };
  return (
    <>
      <Modal
        // {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop // Prevent closing by clicking outside
        onHide={onHideModal} // Close the modal when onHide is triggered
        show={showModal} // Control the modal visibility using show prop
      >
        <Modal.Body className={classes.container}>
          <h4 className={classes.title}>{title}</h4>
          <p className={classes.description}>{description}</p>
          <div className=" w-100  ">
            <Controller
              name="name"
              control={control}
              rules={{ required: "Please provide name" }}
              render={({ field, fieldState }) => (
                <>
                  <label className={classes.label}>Your Name</label>
                  <InputText
                    id={field.name}
                    // disabled={!edit && mode === "update"}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    //   useGrouping={false}
                    locale="en-IN"
                    placeholder=" Name"
                    className={` w-100
             ${fieldState.invalid ? "border border-danger" : ""}  ${
                      classes.inputStyle
                    }  `}
                  />
                </>
              )}
            />
            {getFormErrorMessage("name")}
          </div>
          <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-4 ">
            <div className=" w-50  ">
              <Controller
                name="mobileNo"
                control={control}
                rules={{
                  required: "Please provide number",
                  pattern: {
                    value: /^[0-9]{10}$/g,
                    message: "Enter a valid Mobile No"
                  }
                }}
                render={({ field, fieldState }) => (
                  <>
                    <label className={classes.label}>Your Mobile No</label>
                    <InputText
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      locale="en-IN"
                      placeholder="Mobile Number"
                      style={{
                        background: "transparent"
                      }}
                      className={` w-100  
             ${fieldState.invalid ? "border border-danger" : ""} ${
                        classes.inputStyle
                      }   `}
                    />
                  </>
                )}
              />
              {getFormErrorMessage("mobileNo")}
            </div>
            <div className=" w-50  ">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Please provide email",
                  pattern: {
                    value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                    message: "Enter a valid Email Id"
                  }
                }}
                render={({ field, fieldState }) => (
                  <>
                    <label className={classes.label}>Your Email</label>
                    <InputText
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      locale="en-IN"
                      placeholder="Email"
                      style={{
                        background: "transparent"
                      }}
                      className={` w-100  
             ${fieldState.invalid ? "border border-danger" : ""} ${
                        classes.inputStyle
                      } `}
                    />
                  </>
                )}
              />
              {getFormErrorMessage("email")}
            </div>
          </div>
          <div className=" w-100  ">
            <Controller
              name="position"
              control={control}
              //   rules={{ required: "Please provide name" }}
              render={({ field, fieldState }) => (
                <>
                  <label className={classes.labelMessage}>
                    Applied position for
                  </label>
                  <InputText
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    locale="en-IN"
                    placeholder=" Position"
                    className={` w-100
             ${fieldState.invalid ? "border border-danger" : ""}  ${
                      classes.inputStyle
                    }  `}
                  />
                </>
              )}
            />
          </div>
          <div className=" w-100  ">
            <Controller
              name="message"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label className={classes.labelMessage}>Message</label>
                  <InputTextarea
                    id={field.name}
                    // disabled={!edit && mode === "update"}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    // useGrouping={false}
                    locale="en-IN"
                    placeholder="Write A Message"
                    style={{
                      background: "transparent"
                    }}
                    className={` w-100 
             ${fieldState.invalid ? "border border-danger" : ""} ${
                      classes.inputStyle
                    }   `}
                  />
                </>
              )}
            />
          </div>
          <div className="w-100">
            <label className={classes.label}>Upload Resume</label>
            <input
              type="file"
              ref={fileInputRef}
              name="resume"
              accept=".pdf, .doc, .docx, .txt, "
              onChange={handleFileChange}
              className={`form-control ${classes.customFileInput}  ${
                isSubmitted && !watch("resume") ? "border border-danger" : ""
              }`}
              required // Add the required attribute
            />
            {isSubmitted && !watch("resume") && (
              <small className="text-danger ">Resume is required</small>
            )}
          </div>

          <div className={classes.buttonContainer}>
            <button className={classes.cancelButton} onClick={cancelHandler}>
              Cancel
            </button>
            <button
              className={classes.submitButton}
              onClick={handleSubmit(submitHandler)}
              disabled={isSubmitting}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Loader visible={isSubmitting} />
      {/* <ToastContainer /> */}
    </>
  );
};

export default CareerModal;
