import cardImg1 from "../images/bussinessCards/cardImg1.svg";
import cardImg2 from "../images/bussinessCards/cardImg2.svg";
import cardImg3 from "../images/bussinessCards/cardImg3.svg";
import cardImg4 from "../images/bussinessCards/cardImg4.svg";
import cardImg5 from "../images/bussinessCards/cardImg5.svg";
import cardImg6 from "../images/bussinessCards/cardImg6.svg";

export const bussinessCardsData = [
  {
    image: cardImg1,
    title: "Personalized Catalogue",
    description:"Create custom product catalogues effortlessly to boost your sales strategy"
  },
  {
    image: cardImg2,
    title: " Sales Chat Box",
    description: `Connect with a live sales agent for extra assistance or details after chatting with our chatbot.`
  },
  {
    image: cardImg6,
    title: "Lead Management",
    description: ` Streamline your lead management process to effectively nurture potential customers and boost sales conversions.`
  },
  {
    image: cardImg4,
    title: " Campaign Analytics ",
    description: ` Get valuable insights into your campaign performance, analyze user engagement, and schedule messages for maximum impact.`
  },
  {
    image: cardImg5,
    title: " Marketing  Templates",
    description: ` Save time with pre-built templates for your marketing campaigns, making messaging easier than ever.`
  },
  {
    image: cardImg3,
    title: "Bulk Upload",
    description: `Easily manage products and customers with bulk upload. Streamline operations and save time with efficient data handling.`
  },
];
